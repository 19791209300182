html,
body
{
	margin: 0;
	padding: 0;
	height: 100%;
}

.dictionaryPage
{
	margin-top: 51px;
}

.dictionaryPage div
{
	.vocabRow
	{
		border-bottom: 1px solid silver;
		padding-top:2px;
		padding-bottom:2px;

		.addRemoveIcon {
			display: inline-block;
			text-align: right;
			width: 4%;
		}
	}

	.active
	{
		$activeColor: #aad5f8;
		background-color: lighten($activeColor, 13%);

		-webkit-animation: 1s longpress;
		animation: 1s longpress;

		@-webkit-keyframes longpress {
			20% { background: $activeColor; }
			100% { background: lighten($activeColor, 90%); }
		}

		@keyframes longpress {
			0% { background: darken($activeColor, 10%);}
			70% { background: $activeColor; }
		}
	}

	.flagged
	{
		background-color: #f7e6cd !important;
	}

	.additionalInfo
	{
		font-family: "Arial Unicode MS", Arial, sans-serif;
		font-size: 11px;
		font-style: italic;
		padding-top: 5px;
		padding-left: 10px;
	}

	.hskLevel
	{
		font-family: "Arial Unicode MS", Arial, sans-serif;
		font-size: 11px;
		padding: 5px;
		float: right;
	}

	.character
	{
		font-family: "汉鼎简中楷", "KaiTi";
		font-size: 24px;
		font-weight: normal;
		padding-left: 7px;

		.blue { color:blue; }
		.red { color: red; }
		.green { color: green; }
		.purple { color: purple; }
		.ashgray { color: ashgray; }
	}

	.pinyin
	{
		font-family: "Lucida Sans Unicode", Arial, sans-serif;
		font-size: 18px;
		font-weight: bold;
		padding-top: 2px;
		padding-left: 3px;
	}

	.english
	{
		font-family: "Arial Unicode MS", Arial, sans-serif;
		font-size: 16px;
		font-weight: normal;
		margin-left: 10px;
		vertical-align: text-top;
		color: rgb(104, 103, 103);
	}

	.vocabExpander
	{
		text-align: center;
		height: 5px;
	}

	svg.expanderIcon
	{
		height: 14px;
		color: lightgray;
	}

	.sentenceCollection
	{
		> div
		{
			padding: 5px 0px 5px 0px;
			
			> div.character
			{
				font-size: 20px;
			}
		}
	}

	.hasSentences
	{
		border-bottom-color: lightskyblue;
	}
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media all and (orientation:portrait)
{

}
  
@media all and (orientation:landscape)
{
	// override font sizes for landscape view.
	.dictionaryPage div
	{	
		.character { font-size: 18px; }
		.pinyin { font-size: 12px; }
		.english { font-size: 10px;	}
	}
}
/*

div[role="main"] {
	overflow-y: scroll;
	height: 100%;
}

.ui-content
{
	padding: 0px;
	white-space: nowrap;
}

.ui-mobile .ui-page
{
	background-color: white;
}

#header
{
	background-color:#F9F9F9;
}

.resultsHeader
{
    padding: 3px 2px 0px 5px;
    font-weight: bold;
    background-color: cornsilk;
}

.noResultsFound
{
	padding-top: 5px;
	font-weight:bolder;
	padding-left: 10px;
	width: 100%;
}

div.vocabRow,
div.activeVocabRow
{
	width: 100%;
}
*/