html,
body
{
  margin: 0;
  padding: 0;
  height: 100%;
}

/* SPIN BLOCKER */
#spinBlocker {
  position: fixed;
  width: 100%;
  left:0; right:0; top:0; bottom:0;
  background-color: rgba(255,255,255,0.7);
  z-index: 9999;
  display: none;
}

@-webkit-keyframes spin {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(360deg);}
}
@-moz-keyframes spin {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(360deg);}
}
@keyframes spin {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

#spinBlocker::after {
  content: '';
  display: block;
  position: relative;
  margin: auto;
  top:50%;
  width:40px; 
  height:40px;
  border-style: solid;
  border-color: dodgerblue;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

.content div
{  
  // http://www.kendraschaefer.com/2012/06/chinese-standard-web-fonts-the-ultimate-guide-to-css-font-family-declarations-for-web-design-in-simplified-chinese/
  
  .contentTable
  {
    margin-top: 70px;
    width: 100%;
  
    td
    {
      text-align: center;
      vertical-align: center;
      color: #2196f3;
      padding-left: 5px;
      padding-right: 5px;
    }
  
    tr:last-child td {
      padding-bottom: 30px;
    }
  
    .hidden
    {
      visibility: hidden;
    }
  
    .simplified,
    .traditional
    {
      font-family: Georgia, "Times New Roman", "KaiTi", "楷体", STKaiti, "华文楷体", serif;
      //font-family: "KaiTi"; /*"汉鼎简中楷", */
      font-weight: normal;
      font-size: 84px;

      .blue { color:blue; }
      .red { color: red; }
      .green { color: green; }
      .purple { color: purple; }
      .ashgray { color: ashgray; }
    }
    
    .pinyin
    {
      font-family: "Arial Unicode MS", Arial, sans-serif;
      font-size: 24px;
    }
    
    .english
    {
      //font-family: "Arial Unicode MS", Arial, sans-serif;
      font-family: "Arial Unicode MS", Arial, sans-serif;
      font-size: 24px;
      font-weight: normal;
    }
  } 
  
  #footer {
    position: absolute;
    width: 100%;
  }
  
  .userResponse
  {
    margin: auto;
  
    .dontDisplay
    {
      display: none;
    }
  
    button
    {
      font-size: 16px;
      color: white;
      border-radius: 4px;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      background: lightslategrey; 
      border: transparent;
      line-height: normal;
      letter-spacing: 0.01em;
      width: 145px;
      height: 45px;
      margin: 0px 10px;
    }
  
    #correct {
      background-color: rgb(28, 184, 65); 
    }
    #incorrect {
      background-color: rgb(202, 60, 60);
    }
  }
  
  .sentences 
  {
    td
    {
      padding:5px;
      border-bottom: 1px solid #f8f8f8;
    }
    
    .simplified,
    .traditional
    {
      font-family: "KaiTi"; /*"汉鼎简中楷", */
      font-size: 20px;
      font-weight: normal;
      color: #2196f3;
      padding-bottom: 2px;
    }
  
    .pinyin
    {
      font-family: "Lucida Sans Unicode", Arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
      padding-top: 2px;
    }
  
    .english
    {
      font-family: "Arial Unicode MS", Arial, sans-serif;
      font-size: 14px;
      font-weight: normal;
      padding-top: 2px;
      padding-bottom: 2px;
      /*overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      */
      vertical-align: text-top;
    }
  }

    /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-height: 300px) {
    .contentTable
    { 
      //background-color: lightyellow;
      td {
        height: 80px;
      }
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-height: 325px) {
    .contentTable
    { 
      //background-color: lightcyan;
      td {
        height: 90px;
      }
    }
  }

  @media only screen and (min-height: 370px) {
    .contentTable
    { 
      //background-color: lightcyan;
      td {
        height: 90px;
      }
    }
  }


  @media only screen and (min-height: 560px) {
    .contentTable
    { 
      //background-color: lightskyblue;
      td {
        height: 90px;
      }
    }
  }

  @media only screen and (min-height: 660px) {
    .contentTable
    { 
      //background-color: rgb(166, 218, 250);
      td {
        height: 100px;
      }
    }
  }

  @media only screen and (min-height: 730px) {
    .contentTable
    { 
      //background-color: rgb(250, 239, 253);
      td {
        height: 110px;
      }
    }

    #footer
    {
      bottom: 0px;
      height: 268px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-height: 770px) {
    .contentTable
    { 
      //background-color: rgb(205, 206, 207);
      td {
        height: 120px;
      }
    }
  }

  /*
  @media only screen and (orientation:portrait) and (min-height: 400px)
  {
    .contentTable
    { 
      margin-top: 70px;
      background-color: lightcoral;
  
      td
      {
        height: 100px;
      }
  
      .simplified,
      .traditional
      {
        font-size: 86px;
      }
    }
  
    #footer
    {
      bottom: 0px;
      height: 200px;
    
    }
  }
  
  @media only screen and (orientation:portrait) and (min-height: 700px)
  {
    .contentTable
    {
      margin-top: 70px;
      background-color: lightgreen;
  
      td
      {
        height: 100px;
      }
  
      .simplified,
      .traditional
      {
        font-size: 86px;
      }
    }
  
    #footer
    {
      bottom: 0px;
      height: 200px;
    }
  }*/
  
  @media all and (orientation:landscape)
  {
    .contentTable
    {
      margin-top: 50px;
  
      td
      {
        height: 45px;
      }
  
      .simplified,
      .traditional
      {
        font-size: 56px;
      }
    }
  
    #footer
    {
      bottom: 0px;
      height: 100px;   /* Height of the footer */
    }
  }
}
